<template>
  <div id="app">
    <div class="inner-container">
    <audio-recorder
        upload-url="YOUR_API_URL"
        filename="hochzeit"
        format="wav"
        :attempts="1"
        :time="1"
        :headers="headers"
        :before-recording="callback"
        :pause-recording="callback"
        :after-recording="callback"
        :select-record="callback"
        :before-upload="callback"
        :successful-upload="callback"
        :failed-upload="callback"/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  data () {
    return {
      mp3: '/demo/example.mp3',
      showRecorder: true,
      headers: {
        'X-Custom-Header': 'some data'
      }
    }
  },
  methods: {
    callback (data) {
      console.debug(data)
    }
  }
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
}
#app {
  width: 100vw;
  height: 100vh;
  background-image: url("./assets/bg-blau.png");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-family: 'PT Sans', sans-serif;
  margin-top: -22px;
}

h1 {
  font-family: 'Amalfi Coast', sans-serif;
  text-align: center;
  color: #AC9740;
}

.inner-container {
  padding: 50px 10px;
}
</style>
